<template>
  <div class="page">
    <topheader title="" />
    <div class="register_title">修改登录密码?</div>
    <div class="register_title_welcome">填写信息修改密码</div>
    <div class="input_box">
      <div class="input_box_xia">
        <van-field v-model="tel" type="tel" placeholder="手机号码" disabled />
      </div>
      <div class="input_box_xia">
        <van-field
          v-model="oldpwd"
          type="password"
          placeholder="输入原密码"
        />
      </div>
      <div class="input_box_xia">
        <van-field
          v-model="newpwd1"
          type="password"
          placeholder="设置新6~8位登录密码"
        />
      </div>
      <div class="input_box_xia">
        <van-field v-model="newpwd2" type="password" placeholder="重复新密码" />
      </div>
    </div>
    <div :class="`Sed bton ${all ? 'op' : ''}`" @click="register_click">
      确认
    </div>
  </div>
</template>

<script>
import { updatePwd } from "@/api/register";

export default {
  name: "register",
  data() {
    return {
      userId: this.$store.state.user.userId,
      oldpwd: "",//原密码
      newpwd1: "",
      newpwd2: ""
    };
  },
  computed: {
    tel() {
      return this.$store.getters.tel;
    },
    all() {
      return this.tel && this.newpwd1 && this.newpwd2;
    },
  },
  methods: {
    register_click() {
      if (!this.all) return;
      // 密码位数验证
      if (this.newpwd1.length < 6 || this.newpwd1.length > 8) {
        this.$toast("密码请在6-8位间");
        return;
      }
      // 两次密码验证
      if (this.newpwd1 !== this.newpwd2) {
        this.$toast("两次密码不等");
        return;
      }
      updatePwd(this.oldpwd, this.newpwd1, this.userId).then(res => {
        this.$toast("修改成功");
        setTimeout(() => {
          this.$toast().clear();
          // 退出登录
          this.$store.dispatch("user/logout").then(() => {
            this.$router.back();
          });
        }, 500);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  @include isTop;
}

.op {
  opacity: 1 !important;
}

.Sed {
  width: 84vw;
  height: 12.533vw;
  margin: 0 auto;
  margin-top: 17.333vw;
  background-color: #247dff;
  border-radius: 6.267vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 4.8vw;
  letter-spacing: 0.267vw;
  color: #ffffff;
  opacity: 0.4;
}

.code_gain {
  width: 35vw;
  height: 8vw;
  background-color: #edeff2;
  border-radius: 4vw;
  font-size: 3.467vw;
  color: #909399;
  display: flex;
  align-items: center;
  justify-content: center;
}
.code_gain-on {
  background-color: #008eed;
  color: #ffffff;
}

.input_box_xia {
  height: 11.333vw;
  border-bottom: 0.2vw solid #edeff2;
}

.input_box_xia {
  margin-top: 8vw;
  display: flex;
  justify-content: space-between;
}

.van-field__control,
.van-cell {
  margin: 0;
  padding: 0;
  font-size: 4vw;
  color: #bfc2cc;
}

.input_box {
  width: 84vw;
  margin: 0 auto;
  margin-top: 13.333vw;
}

.register_title {
  font-size: 6.667vw;
  letter-spacing: 0.133vw;
  color: #303133;
  margin-left: 8vw;
  margin-top: 10.667vw;
}

.register_title_welcome {
  font-size: 3.733vw;
  letter-spacing: -0.133vw;
  color: #909399;
  margin-left: 8vw;
  margin-top: 5.067vw;
}
</style>
